import React, { useState, useEffect } from "react"
import { useUserOrgs } from "../../../hooks/use-user"
import { fetch_get } from "../../../utils/fetch"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { useSearch } from "../../../hooks/use-search"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import { MdClose } from "react-icons/md"

const Index = props => {
  const { getAccessTokenSilently } = useAuth0()
  const {
    currentOrg: { index },
  } = useUserOrgs()
  const [loadingData, setLoadingData] = useState(false)
  const [searchProjects, setSearchProjects] = useState([])
  const [errorMeaasge, setErrorMeaasge] = useState("")

  const callback = v => {
    filterProjects(v?.target?.value || "")
  }

  useEffect(() => {
    document.getElementById("projectSearchIcon").parentNode.parentNode.click()
    setErrorMeaasge("")
    props.selectData(null)
    setSearchProjects([])
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.parentOrganization])

  const { callback: handleSearchValue } = useSearch(callback)

  const filterProjects = searchString => {
    setErrorMeaasge("")
    if (!index && !props.parentOrganization) {
      setSearchProjects([])
      props.selectData(null)
      setErrorMeaasge("Please select parent organization")
      return
    }
    if (searchString) {
      let url = index
        ? `projects?index=${index}&page=1&query=${searchString}`
        : `projects?page=1&query=${searchString}&parentOrganization=${props.parentOrganization}`
      setLoadingData(true)
      getAccessTokenSilently()
        .then(accessToken => fetch_get(url, accessToken))
        .then(response => {
          setSearchProjects(response["data"] || [])
          setLoadingData(false)
        })
        .catch(error => {
          console.log(`Error while getting list of projects: ${error}`)
          Sentry.captureException(error)
          setSearchProjects([])
          props.selectData(null)
          setErrorMeaasge("An error occurred while getting the list of projects.")
          setLoadingData(false)
        })
    } else {
      setSearchProjects([])
      props.selectData(null)
      setLoadingData(false)
    }
  }

  return (
    <Autocomplete
      id="ProjectSearch"
      fullWidth
      noOptionsText={
        loadingData ? "Loading..." : errorMeaasge ? errorMeaasge : "No project to select."
      }
      options={searchProjects}
      filterOptions={option => option}
      loading={loadingData}
      closeIcon={
        <MdClose
          id="projectSearchIcon"
          onClick={() => {
            filterProjects("")
          }}
        />
      }
      onChange={(event, value, reason) => {
        if (reason === "clear") {
          props.selectData(null)
        } else if (reason === "select-option") {
          props.selectData(value)
        }
      }}
      getOptionLabel={option => option.name}
      renderOption={option => {
        return (
          <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              style={{ width: "100%", padding: "6px 16px" }}
              onClick={() => {
                props.selectData(option)
                filterProjects(option.name)
              }}
            >
              {option.name}
            </div>
          </>
        )
      }}
      renderInput={params => (
        <TextField
          {...params}
          label="Search for a project..."
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            onChange: event => handleSearchValue(event),
            endAdornment: (
              <React.Fragment>
                {loadingData ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default Index
